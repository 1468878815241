<template>
  <div class="container">

    <div class="grid">
      <div class="col-12">
        <div class="card">
          <h5>Dane Tłumacza </h5>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="firstname">Imie</label>
              <InputText id="firstname" type="text" v-model="form.firstName"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="lastname">Nazwisko</label>
              <InputText id="lastname" type="text" v-model="form.lastName"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="phone">Numer telefonu</label>
              <InputText id="phone" v-model="form.phoneNumber"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="lastname2">Email</label>
              <InputText id="lastname2" type="text" v-model="form.email"/>
            </div>
          </div>
        </div>
        <!--LANGS-->
        <div class="card">
          <h5>Jezyki</h5>
          <div class="p-fluid formgrid grid">
            <div class=" col-12 mb-5">
              <h6>Języki natywne</h6>
              <span>{{ nativeLangsJoined }}</span>


            </div>
            <div class=" col-12">
              <h6>Konfiguracja języków</h6>
              <!--              <ProfileLanguageCombitation-->
              <!--                  v-for="post in posts"-->
              <!--                  :key="post.id"-->
              <!--                  :sourceLang="post.sourceLang"-->
              <!--                  :destinationLang="post.destinationLang"-->
              <!--                  :sworn="post.sworn"-->
              <!--              />-->
              <DataTable :value="form.langs" responsiveLayout="scroll">
                <Column field="source" header="Język Zrodłowy"></Column>
                <Column field="destiny" header="Język Docelowy"></Column>
                <Column field="sworn" header="Przysięgły">
                  <template #body="slotProps">
                    {{ formatBoolean(slotProps.data.sworn) }}
                  </template>
                </Column>
                <!--                <Column field="fileName" headerStyle="min-width:10rem;">-->
                <!--                  <template #body="slotProps">-->
                <!--                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"-->
                <!--                            @click="deleteLangConfiguration(slotProps.data)"/>-->
                <!--                  </template>-->
                <!--                </Column>-->
              </DataTable>
              <!--              <Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2 mt-2"-->
              <!--                      @click="addLangConfiguration()"-->
              <!--              >-->
              <!--              </Button>-->
            </div>
          </div>
        </div>
        <!--ADRES-->
        <div class="card">
          <h5>Adres nadawczy</h5>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="state">Preferowana forma nadania</label>
              <Dropdown id="state" v-model="form.address.deliveryType" :options="dropdownItems"
                        optionLabel="name" option-value="code" placeholder="Paczkomat/Kurier"></Dropdown>
            </div>

            <div class="field col-12 md:col-6">
              <label for="street">Ulica</label>
              <InputText id="street" type="text" v-model="form.address.street"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="number">Numer budynku/mieszkania</label>
              <InputText id="number" type="text" v-model="form.address.buildingNumber"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="city">City</label>
              <InputText id="city" type="text" v-model="form.address.city"/>
            </div>

            <div class="field col-12 md:col-6">
              <label for="zip">Kod pocztowy</label>
              <InputText id="zip" type="text" v-model="form.address.postalCode"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="parcel">Kod paczkomatu</label>
              <InputText id="parcel" type="text" placeholder="np. WAW53M" v-model="form.address.parcelId"/>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {languages} from "@/services/constant";
import UserService from "@/services/user.service";

export default {
  name: 'Profile',
  components: {},
  methods: {
    deleteLangConfiguration() {
    },
    addLangConfiguration() {
    },
    formatBoolean(value) {
      if (value === true) {
        return "Tak"
      } else return "Nie"
    }
  },
  computed: {
    nativeLangsJoined() {
      if (this.form.nativeLangs == null || this.form.nativeLangs.length === 0) {
        return "-"
      }
      return this.form.nativeLangs.join(", ")
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    UserService.getProfile()
        .then((resp) => {
              this.form = resp.data
            },
            (error) => {
              console.log("Failed to get offerId" + this.offerId, error)
            }
        )


  },
  data() {
    return {
      posts: [
        {id: 1, sourceLang: 'PL', destinationLang: 'DE', sworn: 'true'},
        {id: 2, sourceLang: 'PL', destinationLang: 'EN', sworn: 'true'},
        {id: 3, sourceLang: 'PL', destinationLang: 'RU', sworn: 'false'},
      ],
      form: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: {
          deliveryType: null,
          street: "",
          buildingNumber: "",
          city: "",
          postalCode: "",
          parcelId: "",
        },
        nativeCountries: null,
        sample2: null,
        sample1: null,

        native: false,
        sworn: false,

      },
      langs: languages,
      dropdownItem: null,
      dropdownItems: [
        {name: 'Paczkomat', code: 'PARCEL'},
        {name: 'Kurier', code: 'COURIER'},
      ]
    }
  }
};
</script>