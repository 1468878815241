export interface Language {
    name: string,
    code: string,
    countryISOA3: string,
}

export const languages: Language[] = [
    {name: 'Polski', code: 'pl', countryISOA3: 'PL'},
    {name: 'Niemiecki', code: 'de', countryISOA3: 'DE'},
    {name: 'Angielski', code: 'en', countryISOA3: 'UK'},
    {name: 'Rosyjski', code: 'ru', countryISOA3: 'RU'},
]